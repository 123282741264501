<template>
  <div id="app">
    <!-- <FloatingBallVue>
      <van-icon size="40" name="wap-home-o" />
    </FloatingBallVue> -->
    <router-view> </router-view>
  </div>
</template>

<script>
// import FloatingBallVue from "./components/floatingBall1.vue";
export default {
  // components: {
  //   FloatingBallVue,
  // },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  padding: 0;
  margin: 0;
  font-family: 'LTXH',serif;
}
::-webkit-scrollbar {
  width: 0 !important;
}
ul {
  list-style-type: disc !important;
}
li {
  list-style-position: outside !important;
}
.van {
  font-family: 'LTXH', sans-serif !important;
}
</style>
