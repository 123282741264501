import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import store from './store'
import router from '../src/router/router'
import 'vant/lib/index.css';
import VueTouch from 'vue-touch'
import '../src/assets/font/font-style.css'
import cookies from 'vue-cookies';



Vue.prototype.$cookies = cookies
Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
