import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalPush1 = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalPush1.call(this, location).catch(err => err)
}

const routes = [
  // 一级菜单
  {
    path: '/',
    name: 'menu-1',
    component: () => import('../view/directoryPage/menu-1.vue')
  },
  // 二级页面
  {
    path: '/secondaryPages',
    name: 'secondaryPages',
    component: () => import('../view/twoPage/secondaryPages.vue')
  },
  // 二级页面产品介绍
  {
    path: '/secondaryPagesPPJS',
    name: 'secondaryPagesPPJS',
    component: () => import('../view/twoPage/secondaryPagesPPJS.vue')
  },
  // 二级页面产品介绍
  {
    path: '/secondaryPagesHDXX',
    name: 'secondaryPagesHDXX',
    component: () => import('../view/twoPage/secondaryPagesHDXX.vue')
  },
  // 二级页面应用领域
  {
    path: '/secondaryPagesYYLY',
    name: 'secondaryPagesYYLY',
    component: () => import('../view/twoPage/secondaryPagesYYLY.vue')
  },
  // 二级页面联系我们
  {
    path: '/secondaryPagesLXWM',
    name: 'secondaryPagesLXWM',
    component: () => import('../view/twoPage/secondaryPagesLXWM.vue')
  },
  // 三级页面
  {
    path: '/levelThreePage',
    name: 'levelThreePage',
    component: () => import('../view/threePage/levelThreePage.vue')
  },
  // 三级页面 活动信息
  {
    path: '/levelThreePageHDXX',
    name: 'levelThreePageHDXX',
    component: () => import('../view/threePage/levelThreePageHDXX.vue')
  },
  // 三级页面 在线课堂
  {
    path: '/leveThreePageZXKT',
    name: 'leveThreePageZXKT',
    component: () => import('../view/threePage/leveThreePageZXKT.vue')
  },
  // 三级页面 在线课堂
  {
    path: '/leveThreePageZXKT2',
    name: 'leveThreePageZXKT2',
    component: () => import('../view/threePage/leveThreePageZXKT2.vue')
  },
  // 四级页面
  {
    path: '/levelFourPages',
    name: 'levelFourPages',
    component: () => import('../view/fourPage/levelFourPages.vue')
  },
  // 四级页面
  {
    path: '/levelFourPagesCPJS',
    name: 'levelFourPagesCPJS',
    component: () => import('../view/fourPage/levelFourPagesCPJS.vue')
  },
  // 五级页面
  {
    path: '/levelFivepage',
    name: 'levelFivepage',
    component: () => import('../view/fivepages/levelFivepage.vue')
  },
  // 五级页面
  {
    path: '/levelFivepagePPJS',
    name: 'levelFivepagePPJS',
    component: () => import('../view/fivepages/levelFivepagePPJS.vue')
  },
  // 五级页面
  {
    path: '/levelFivepageHDXX',
    name: 'levelFivepageHDXX',
    component: () => import('../view/fivepages/levelFivepageHDXX.vue')
  },
  //详情页
  {
    path: '/detailsPage',
    name: 'detailsPage',
    component: () => import('../view/sixPages/detailsPage.vue')
  },
  //预览PDF
  {
    path: '/previewPage',
    name: 'previewPage',
    component: () => import('../view/sixPages/previewPage.vue')
  },
  //登录页
  {
    path: '/loginPage',
    name: 'loginPage',
    component: () => import('../view/login/loginPage.vue')
  },
  // 注册页
  {
    path: '/registeredPage',
    name: 'registeredPage',
    component: () => import('../view/login/registeredPage.vue')
  },
  // 个人信息页
  {
    path: '/userInfoPage',
    name: 'userInfoPage',
    component: () => import('../view/login/userInfoPage.vue')
  },
  // 需登录页面
  {
    path: '/levelFourPagesLoginWXCY',
    name: 'levelFourPagesLoginWXCY',
    component: () => import('../view/fourPage/levelFourPagesLoginWXCY.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     console.log('请先登录');
//     next({
//       path: '/loginPage',
//     })
//   } else {
//     next()
//   }

// })

export default router
